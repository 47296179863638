<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      initGameData: "gameData/init"
    })
  },
  mounted() {
    window.addEventListener("message", e => {
      if (e.data.event === "init") {
        this.initGameData(e.data);
      }
    });
  }
};
</script>
<style>
@import "./styles/variables.css";
</style>
<style></style>
