import Vue from "vue";
import Vuex from "vuex";
import gameDataStore from "./gameInfoStore";
import jsonDataStore from "./jsonDataStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    gameData: gameDataStore,
    json: jsonDataStore,
    jokerBonus: jsonDataStore
  },
  actions: {}
});
