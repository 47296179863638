<template>
  <div>
    <table class="table table-bordered text-center">
      <thead>
        <th v-for="header in tableHeader">{{ header }}</th>
      </thead>
      <tbody>
        <tr v-for="term in terms">
          <td>{{ term['english'] }}</td>
          <td>{{ term['translated'] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: "translations",
    props: {
      jsonData: {
        type: Object,
        default: ()=>{}
      }
    },
    data() {
      return {}
    },

    computed: {
      header() {
        return this.jsonData['title']
      },

      text() {
        return this.jsonData['text']
      },

      tableHeader() {
        return this.jsonData['tableHeader']
      },

      terms() {
        return this.jsonData['tableBody']
      }
    }
  }
</script>

<style scoped>
  .table {
    table-layout: fixed;
  }
</style>
