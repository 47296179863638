import Game from "../components/game/game";
import Commons from "../components/commons/commons";
import { mapActions, mapGetters } from "vuex";
import helpers from "../helpers/window/windowHelpers";

export const PageBase = {
  components: { Commons, Game },
  props: ["gameName", "language"],
  data() {
    return { pageName: "paragraph" };
  },

  computed: {
    ...mapGetters({
      jsonFile: "json/config",
      fetching: "json/isLoading"
    }),
    fileName() {
      return `${this.gameName}_${this.language}`;
    },

    defaultFileName() {
      return `${this.gameName}_en`;
    },
    url() {
      return `/asset/games/${this.gameName}/json/${this.skinnedFileName(
        this.fileName
      )}.json`;
    },

    defaultUrl() {
      return `/asset/games/${this.gameName}/json/${this.skinnedFileName(
        this.defaultFileName
      )}.json`;
    }
  },

  methods: {
    ...mapActions({
      loadJsonFile: "json/loadJson"
    }),
    loadCSS(suffix) {
      let file = document.createElement("link");
      file.rel = "stylesheet";
      file.href = `/asset/games/${this.gameName}/style${suffix}.css`;
      document.head.appendChild(file);
    },
    skinnedFileName(filename) {
      let skinID = this.$route.query.skinID;
      if (skinID === "null" || skinID === "undefined") {
        skinID = null;
      }
      return `${filename}${skinID ? `_${skinID}` : ""}`;
    }
  },
  created() {
    helpers.postToParent({ code: "ready", page: this.pageName });
    this.loadJsonFile({ url: this.url, defaultUrl: this.defaultUrl });
    this.loadCSS("");
    if (this.$route.query.skinID !== "null") {
      this.loadCSS(`_${this.$route.query.skinID}`);
    }
  }
};
