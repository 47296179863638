<template>
  <div>
    <table class="table table-bordered text text-center">
      <thead>
        <tr>
          <th v-for="(header, headerIndex) in tableHeaders" :key="headerIndex">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) of tableRows" :key="rowIndex">
          <td
            v-for="(data, dataIndex) of row"
            :key="dataIndex"
            v-html="getTableDataContent(data)"
          ></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
export default {
  name: "genericTable",
  props: ["sectionIndex", "contentIndex", "componentIndex", "storePrefix"],
  methods: {
    getTableDataContent(data) {
      if (data.type === "text") {
        return data.val[0];
      }

      if (data.type === "img") {
        return `<img src="${this.imageSrc(data.val)}" class="image--s" />`;
      }

      if (data.type === "imgArr") {
        return data.val
          .map(src => `<img src="${this.imageSrc(src)}" class="image--s" />`)
          .join("");
      }
    },
    imageSrc(src) {
      const midPath = this.useCommons ? "commons" : `games/${this.gameName}`;
      return `/asset/${midPath}/img/${src}.png`;
    }
  },

  beforeCreate() { 
    let namespace = this.$options.propsData.storePrefix;
    const { mapGetters } = createNamespacedHelpers(namespace)

    this.$options.computed = {
      ...mapGetters({
        componentConfig: "componentData"
      }),

      tableHeaders() {
        return this.componentConfig(
          this.sectionIndex,
          this.contentIndex,
          this.componentIndex
        ).tableHeaders;
      },
      tableRows() {
        return this.componentConfig(
          this.sectionIndex,
          this.contentIndex,
          this.componentIndex
        ).tableRows;
      },
      useCommons() {
        return this.componentConfig(
          this.sectionIndex,
          this.contentIndex,
          this.componentIndex
        ).useCommons;
      },
      gameName() {
        return this.$route.params["gameName"];
      }
    }
  },

  // computed: {
  //   ...mapGetters({
  //     componentConfig: "json/componentData"
  //   }),

  //   tableHeaders() {
  //     return this.componentConfig(
  //       this.sectionIndex,
  //       this.contentIndex,
  //       this.componentIndex
  //     ).tableHeaders;
  //   },
  //   tableRows() {
  //     return this.componentConfig(
  //       this.sectionIndex,
  //       this.contentIndex,
  //       this.componentIndex
  //     ).tableRows;
  //   },
  //   useCommons() {
  //     return this.componentConfig(
  //       this.sectionIndex,
  //       this.contentIndex,
  //       this.componentIndex
  //     ).useCommons;
  //   },
  //   gameName() {
  //     return this.$route.params["gameName"];
  //   }
  // }
};
</script>
