<template>
  <div>
    <div
      v-for="(section, i) of jsonFile.sections"
      :key="`section${i}`"
      class="container section"
    >
      <h2 v-if="section.title">{{ section.title }}</h2>
      <div
        v-for="(sectionContent, j) of section.content"
        :key="`section${i}-${j}`"
      >
        <text-layout
          v-if="getSectionContentLayoutType(sectionContent) === 'text'"
          :sectionIndex="i"
          :contentIndex="j"
          :storePrefix="storePrefix"
        />
        <img-layout
          v-if="getSectionContentLayoutType(sectionContent) === 'img'"
          :sectionIndex="i"
          :contentIndex="j"
          :storePrefix="storePrefix"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { createNamespacedHelpers } from "vuex";
import OnePart from "../onePart";
import WinLines from "../winLines.vue";
import BetTable from "../betTable";
import SymbolTable from "../symbolTable";
import Buttons from "../commons/buttons";
import Translations from "../commons/translations";
import genericTable from "../genricTable.vue";
import TextLayout from "../layouts/text.vue";
import ImgLayout from "../layouts/withImg.vue";

export default {
  name: "game",
  components: {
    OnePart,

    Translations,
    Buttons,
    TextLayout,
    ImgLayout
  },
  props: {
    storePrefix: {
      type: String,
      default: "json"
    },
    gameName: {
      type: String,
      default: ""
    },
    language: {
      type: String,
      default: "en"
    }
  },

  beforeCreate() {
    let namespace = this.$options.propsData.storePrefix;
    const { mapGetters } = createNamespacedHelpers(namespace);

    this.$options.computed = {
      ...mapGetters({
        jsonFile: "config"
      })
    };
  },

  data() {
    return {};
  },

  // computed: {
  //   ...mapGetters({
  //     // jsonFile: 'json/config'
  //     // jsonFile: this.storePrefix+'/config'
  //     jsonFile: state => state.config
  //   })
  // },

  methods: {
    getSectionContentLayoutType(sectionContent) {
      if (
        ["imgLeft", "imgRight", "imgUnder", "imgOver"].includes(
          sectionContent.layout
        )
      ) {
        return "img";
      }
      if (sectionContent.layout === "text") {
        return "text";
      }
      return "text";
    }
  },

  created() {},

  watch: {}
};
</script>

<style scoped></style>
