<template>
  <div v-if="rtp !== null">
    <h2>{{ title }}</h2>
    <p>{{ text[0] }} {{ rtpNorm }}</p>
    <p>{{ text[1] }}</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["sectionIndex", "contentIndex", "componentIndex"],
  computed: {
    ...mapGetters({
      componentConfig: "json/componentData",
      rtp: "gameData/rtp",
      legislation: "gameData/legislation"
    }),
    rtpNorm() {
      return `${this.rtp.toFixed(2)}%`;
    },
    title() {
      return this.componentConfig(
        this.sectionIndex,
        this.contentIndex,
        this.componentIndex
      ).title;
    },
    text() {
      return this.componentConfig(
        this.sectionIndex,
        this.contentIndex,
        this.componentIndex
      ).text;
    }
  }
};
</script>

<style></style>
