<template>
  <div class="flex-container flex-row flex-wrap justify-content-center">
    <div
      v-for="(symbol, symbolIndex) of symbols"
      :key="`symbol${symbolIndex}`"
      class="flex-container flex-column align-align-content-center mx-2"
    >
      <img :src="image(symbol.index)" :title="symbol.name" class="image" />
      <table class="text table table-borderless d-flex flex-column-reverse">
        <tr
          v-for="(factor, symbolCountIndex) of symbol.factors"
          :key="`symbol${symbolIndex}-${symbolCountIndex}`"
          class="d-flex flex-row justify-content-between"
        >
          <td class="align-left py-0">{{ factor.label }}</td>
          <td class="align-right py-0">
            <span>{{ factor.text }}</span>
            <img
              v-if="
                factor.text !== '-' && legislation === 'cz' && jokerSpinPossible
              "
              src="/asset/commons/img/los_joker.png"
              alt="Joker spin icon"
              style="width: 1.25rem; margin-left: 5px; vertical-align: text-bottom;"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "symbolTable",
  props: ["sectionIndex", "contentIndex", "componentIndex"],
  data() {
    return {};
  },

  methods: {
    image(symbol) {
      return `/asset/games/${this.gameName}/img/${symbol}${
        this.skinID ? `_${this.skinID}` : ""
      }.png`;
    }
  },

  computed: {
    ...mapGetters({
      componentConfig: "json/componentData",
      symbolDef: "gameData/symbolDefinitions",
      legislation: "gameData/legislation"
    }),
    config() {
      return this.componentConfig(
        this.sectionIndex,
        this.contentIndex,
        this.componentIndex
      );
    },
    jokerSpinPossible() {
      return (
        this.symbolDef.findIndex(
          el => el.index === 666 && el.type === "scratchCard"
        ) > -1
      );
    },
    symbols() {
      const factorsUsed = new Map();
      this.symbolDef
        .filter(s => s.index !== 666)
        .map(s => s.factors)
        .forEach(fArr => {
          fArr.forEach((f, i) => {
            factorsUsed.set(i, factorsUsed.get(i) || f > 0);
          });
        });
      const res = this.symbolDef
        .filter(s => s.index !== 666)
        .map(({ index, name, factors }) => {
          return {
            index,
            name,
            factors: factors
              .map((factor, index) => {
                return {
                  label: `${index + 1}`,
                  text: factor > 0 ? `${factor} x ${this.bet}` : "-"
                };
              })
              .filter((_, i) => factorsUsed.get(i))
          };
        });

      for (let override of this.symbolOverrides) {
        const index = res.findIndex(item => item.index === override.index);
        if (index > -1)
          res[res.findIndex(item => item.index === override.index)].factors =
            override.factors;
      }
      return res;
    },
    symbolOverrides() {
      return this.config.symbols || [];
    },
    bet() {
      return this.config.bet || "bet";
    },

    gameName() {
      return this.$route.params["gameName"];
    },
    skinID() {
      const res = this.$route.query.skinID;
      if (res === "null") {
        return null;
      }
      return res;
    }
  }
};
</script>

<style scoped></style>
