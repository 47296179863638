import Vue from "vue";
import VueRouter from "vue-router";

import paragraphPage from "../views/paragraphPage";
import infopagePage from "../views/infopagePage";
import pageNotFound from "../views/pageNotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:gameName/:language",
    name: "paragraphPage",
    component: paragraphPage,
    props: route => ({
      gameName: route.params.gameName,
      language: route.params.language || "en"
    })
  },
  {
    path: "/:gameName/:language/info",
    name: "infoPage",
    component: infopagePage,
    props: route => ({
      gameName: route.params.gameName,
      language: route.params.language || "en"
    })
  },
  {
    path: "*",
    name: "pageNotFound",
    component: pageNotFound
  }
];

const router = new VueRouter({
  routes, // short for routes: routes
  mode: "history",
  linkExactActiveClass: "active"
  //base:  process.env.BASE_URL,
});

export default router;
