<template>
  <div>
    <one-part
      v-for="(key, index) in filteredKeys"
      :json-data="commonsJson[key]"
      :section="key"
      :type="index % 2 ? 'light' : 'dark'"
      :key="key"
      :game-r-t-p="gameRTP"
    ></one-part>
    <p v-if="badRequest">
      {{ errorMsg }}
    </p>
    <div v-if="fetching" style="text-align: center">
      <b-spinner style="" class="spinner light" variant=""></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OnePart from "../onePart";
import Buttons from "../commons/buttons";
import Translations from "../commons/translations";

export default {
  name: "commons",
  components: { OnePart, Buttons, Translations },
  props: {
    gameRTP: {
      type: Object,
      default: () => {
        {
          value: "95.00%";
        }
      }
    },
    language: {
      type: String,
      default: "en"
    }
  },
  data() {
    return {
      commonsJson: {},
      errorMsg: "",
      fetching: false,
      badRequest: false,
      forbiddenHeader: []
    };
  },
  computed: {
    gameName() {
      return this.$route.params["gameName"];
    },

    fileName() {
      // CUSTOM DESKTOP UI SUPPORT
      let customUIGames = [
        "bad_wolf",
        "secret_rose",
        "retro_wheels",
        "tutti_frutti"
      ];
      // If this game has custom UI, deliver different file name
      if (customUIGames.includes(this.gameName)) {
        return `customUI_${this.language}`;
      } else {
        return `commons_${this.language}`;
      }
    },

    defaultFileName() {
      // CUSTOM DESKTOP UI SUPPORT
      let customUIGames = [
        "bad_wolf",
        "secret_rose",
        "retro_wheels",
        "tutti_frutti"
      ];
      // If this game has custom UI, deliver different file name
      if (customUIGames.includes(this.gameName)) {
        return `customUI_en`;
      } else {
        return `commons_en`;
      }
    },

    keys() {
      return Object.keys(this.commonsJson);
    },

    filteredKeys() {
      return this.keys.filter(key => this.forbiddenHeader.indexOf(key) === -1);
    },

    url() {
      return `../asset/commons/json/${this.fileName}.json`;
    },

    defaultUrl() {
      return `../asset/commons/json/${this.defaultFileName}.json`;
    }
  },

  methods: {
    getJsonFile() {
      this.fetching = true;
      this.$store
        .dispatch("axiosFetch", this.url)
        .then(response => {
          this.commonsJson = response["data"];
          this.fetching = false;
        })
        .catch(error => {
          this.$store
            .dispatch("axiosFetch", this.defaultUrl)
            .then(response => {
              this.commonsJson = response["data"];
              this.fetching = false;
            })
            .catch(error => {
              console.error(error);
              this.badRequest = true;
              this.fetching = false;
              this.errorMsg = error["response"];
            });
        });
    }  
  },

  created() {
    this.getJsonFile();
  }
};
</script>

<style scoped>
/*.spinner {
  position: absolute;
  left: 50%;
  top: 35%;
  width: 4rem;
  height: 4rem;
  color: #f9dc34
} */
</style>
