<template>
  <div>
    <table class="table table-bordered text-center">
      <thead>
        <th v-for="key in header">
          {{ key }}
        </th>
      </thead>
      <tbody>
        <tr v-for="button in buttons">
          <td>
            <img
              v-for="btn in button['mobile']"
              :src="image(btn)"
              :title="btn"
              width="50px"
              height="auto"
            />
          </td>
          <td>
            <img
              v-for="btn in button['desktop']"
              :src="image(btn)"
              :title="btn"
              width="50px"
              height="auto"
            />
          </td>
          <td>
            {{ button.text }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "buttons",
  props: ["jsonData", "gameName"],
  data() {
    return {};
  },

  methods: {
    image(symbol) {
      // CUSTOM DESKTOP UI SUPPORT
      let customUIGames = [
        "bad_wolf",
        "secret_rose",
        "retro_wheels",
        "tutti_frutti"
      ];
      // If this game has custom UI, deliver different paths
      if (customUIGames.includes(this.gameName)) {
        return (
          "../asset/games/" + this.gameName + "/img/customUI/" + symbol + ".png"
        );
      } else {
        return "../asset/commons/img/" + symbol + ".png";
      }
    }
  },

  computed: {
    header() {
      return this.jsonData["tableHeader"];
    },

    buttons() {
      return this.jsonData["tableBody"];
    }
  }
};
</script>

<style scoped></style>
