<template>
  <div :class="type" class="container-fluid">
    <div class="row">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h3>
              {{ header }}
            </h3>
            <span
              v-for="(paragraph, paragraphIndex) in paragraphs"
              :key="`paragraph${paragraphIndex}`"
            >
              <h4 v-if="paragraph['subheader']">
                {{ paragraph["subheader"] }}
              </h4>
              <div class="rubric">
                <p
                  v-for="(line, lineIndex) in paragraph.text"
                  :key="`paragraph${paragraphIndex}-${lineIndex}`"
                >
                  {{ line }}
                  <span v-if="section === 'rtp'">
                    {{ gameRTP["value"] }}
                  </span>
                </p>

                <table
                  v-if="paragraph['tableInText']"
                  class="table table-bordered table-hover text-center col-6 container-fluid"
                  :class="type"
                >
                  <tr>
                    <th
                      v-for="(header, headerIndex) in paragraph['tableInText'][
                        'header'
                      ]"
                      :key="
                        `paragraph${paragraphIndex}-tableHeader${headerIndex}`
                      "
                    >
                      {{ header }}
                    </th>
                  </tr>
                  <tr
                    v-for="(body, rowIndex) in paragraph['tableInText']['body']"
                    :key="`paragraph${paragraphIndex}-row${rowIndex}`"
                  >
                    <td
                      v-for="{ data, dataIndex } in body"
                      :key="
                        `paragraph${paragraphIndex}-row${rowIndex}-data${dataIndex}`
                      "
                    >
                      {{ data }}
                    </td>
                  </tr>
                </table>
              </div>
            </span>
            <bet-table
              v-if="section === 'gameOverview'"
              :gameName="gameName"
              :language="language"
              :type="type"
            >
            </bet-table>
            <symbol-table
              v-if="section === 'payTable'"
              :gameName="gameName"
              :language="language"
              :jsonData="jsonData"
              :type="type"
            >
            </symbol-table>
            <buttons
              v-if="section === 'gameFunctions'"
              :gameName="gameName"
              :language="language"
              :jsonData="jsonData"
              :type="type"
            ></buttons>
            <translations
              v-if="section === 'translations'"
              :gameName="gameName"
              :language="language"
              :jsonData="jsonData"
              :type="type"
            ></translations>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BetTable from "./betTable";
import SymbolTable from "./symbolTable";
import Buttons from "./commons/buttons";
import Translations from "./commons/translations";
export default {
  name: "onePart",
  components: { Translations, Buttons, SymbolTable, BetTable },
  props: {
    jsonData: {
      type: Object,
      default: () => {
        {
        }
      }
    },
    gameRTP: {
      type: Object,
      default: () => {
        {
          value: "95.00%";
        }
      }
    },
    type: {
      type: String,
      default: "dark"
    },
    section: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },

  computed: {
    header() {
      return this.jsonData["title"];
    },

    paragraphs() {
      return this.jsonData["content"];
    },

    gameName() {
      return this.$route.params["gameName"];
    },

    language() {
      return this.$route.params["language"];
    }
  }
};
</script>

<style scoped>
/*div {
    padding: 10px;

  }

  */
/*#spinner {
  height: 100vh;
  width: 100vw;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 35%;
  width: 4rem;
  height: 4rem;
  color: #17a9e2;
}

.dark {
  background: linear-gradient(rgb(4, 23, 38) 0%, rgb(0, 52, 72) 100%);
}

.light {
  background-color: #181920;
}*/
</style>
