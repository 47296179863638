<template>
  <div>
    <p v-if="tableTitle">{{ tableTitle }}</p>
    <table class="text table table-bordered text-center">
      <thead>
        <tr>
          <th class="diagonal-stripe">
            <span id="th-bet">{{ betTitle }}</span>
            <span id="th-denom">{{ coinTitle }}</span>
          </th>
          <th v-for="(coin, index) in values.coins" :key="index">
            {{ coin }}
          </th>
        </tr>
      </thead>
      <tr v-for="bet in values.bets" :key="bet">
        <td v-text="bet">{{ bet }}</td>
        <td v-for="coin in values.coins" :key="coin">
          {{ checkBounds((coin * bet * 100).toFixed(2) / 100) }}
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "betTable",
  props: ["sectionIndex", "contentIndex", "componentIndex"],
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      componentConfig: "json/componentData",
      values: "gameData/betSet"
    }),
    config() {
      return this.componentConfig(
        this.sectionIndex,
        this.contentIndex,
        this.componentIndex
      );
    },
    betTitle() {
      return this.config.bet;
    },
    coinTitle() {
      return this.config.coin;
    },
    tableTitle() {
      return this.config.title;
    }
  },

  methods: {
    checkBounds(value) {
      if (
        this.$store.state.gameData.min_bet &&
        this.$store.state.gameData.max_bet
      ) {
        let realValue;
        if (this.$store.state.gameData.decimal_places) {
          realValue =
            value *
            Math.pow(10, Number(this.$store.state.gameData.decimal_places));
        } else {
          realValue = value * 100;
        }
        if (
          realValue >= this.$store.state.gameData.min_bet &&
          realValue <= this.$store.state.gameData.max_bet
        ) {
          return value;
        } else {
          return "";
        }
      } else {
        return value;
      }
    }
  }
};
</script>

<style scoped>
.diagonal-stripe {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'> <path d='M0 0 L0 10 L10 10' fill='rgba(240, 240, 240, 0.3)' /></svg>")
    no-repeat center center;
  background-size: 100% 100%, auto;
  position: relative;
  font-size: 0.9em;
  width: 150px;
}
#th-bet {
  position: absolute;
  left: 2%;
  margin: 0;
  top: 50%;
  bottom: 0;
}

@supports (-ms-ime-align: auto) {
  #th-bet {
    top: 95%;
  }
}

#th-denom {
  position: absolute;
  top: 2%;
  right: 3%;
  width: max-content;
}
tr td:first-child,
tr th:not(:first-child) {
  background-color: rgba(240, 240, 240, 0.3);
}
</style>
