<template>
  <p>{{ text }} {{ buildTime }}</p>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["sectionIndex", "contentIndex", "componentIndex"],
  computed: {
    ...mapGetters({
      componentConfig: "json/componentData"
    }),
    buildTime() {
      return new Date(parseInt(process.env.VUE_APP_BUILD_TIME)).toLocaleString(
        this.$route.params["language"]
      );
    },
    text() {
      return this.componentConfig(
        this.sectionIndex,
        this.contentIndex,
        this.componentIndex
      ).text;
    }
  }
};
</script>

<style></style>
