export default {
  getOpener() {
    return window.opener || window.parent;
  },
  postToParent(data) {
    this.getOpener().postMessage(data, "*");
  }
};
// temporary solution for games (27, 81) with no winlines from server
export function fillWinLinesTable(reelsCount, symbolCount) {
  let winLinesCount = Math.pow(symbolCount, reelsCount);
  let winlinesArr = [];
  for (let i = 0; i < winLinesCount; i++) {
    let winline = i
      .toString(symbolCount)
      .padStart(reelsCount, 0)
      .split("");
    let tmpArr = [];
    winline.forEach((element, i) => {
      tmpArr.push({ offs: parseInt(element), reel: i });
    });
    winlinesArr.push(tmpArr);
  }
  return winlinesArr;
}
