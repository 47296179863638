<template>
  <div>
    <h3 v-if="sectionContent.subHeader">{{ sectionContent.subHeader }}</h3>
    <div class="flex-container justify-content-center">
      <img
        :src="imgSrc"
        v-if="sectionContent.layout === 'imgLeft'"
        class="image"
      />
      <div v-if="sectionContent.text">
        <img
          :src="imgSrc"
          v-if="sectionContent.layout === 'imgOver'"
          class="image"
        />
        <p
          v-for="text of sectionContentTexts"
          :key="`section${sectionIndex} content${contentIndex} text${text}`"
          v-html="text"
        ></p>
        <img
          :src="imgSrc"
          v-if="sectionContent.layout === 'imgUnder'"
          class="image"
          alt="asdf"
        />
      </div>

      <img
        :src="imgSrc"
        v-if="sectionContent.layout === 'imgRight'"
        class="image"
      />
    </div>
    <component-wrapper
      v-for="(component, componentIndex) of sectionContent.components"
      :key="`section${sectionIndex}-${contentIndex} component${componentIndex}`"
      :component="component"
      :componentIndex="componentIndex"
      :contentIndex="contentIndex"
      :sectionIndex="sectionIndex"
      :storePrefix="storePrefix"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
import componentWrapper from "../componentWrapper.vue";
export default {
  components: { componentWrapper },
  // props: ["sectionIndex", "contentIndex"],
  props: {
    storePrefix: {
      type: String,
      default: "json"
    },
    sectionIndex: {
      type: Number,
      required: true
    },
    contentIndex: {
      type: Number,
      required: true
    }
  },

  beforeCreate() {
    let namespace = this.$options.propsData.storePrefix;
    const { mapGetters } = createNamespacedHelpers(namespace);

    this.$options.computed = {
      ...mapGetters({
        jsonFile: "config"
      }),

      sectionContent() {
        return this.jsonFile.sections[this.sectionIndex].content[
          this.contentIndex
        ];
      },
      gameName() {
        return this.$route.params["gameName"];
      },
      imgSrc() {
        if (namespace == "json") {
          return (
            "/asset/games/" +
            this.gameName +
            "/img/" +
            this.sectionContent.img +
            ".png"
          );
        }
        return (
          "/asset/components/joker_bonus/img/" +
          this.sectionContent.img +
          ".png"
        );
      },
      sectionContentTexts() {
        const content = this.sectionContent;
        if (content !== undefined) {
          return content.text.split("\n");
        }
        return [];
      }
    };
  }
  // computed: {
  //   // ...mapGetters({
  //   //   jsonFile: "json/config"
  //   // }),
  //   sectionContent() {
  //     return this.jsonFile.sections[this.sectionIndex].content[
  //       this.contentIndex
  //     ];
  //   },
  //   gameName() {
  //     return this.$route.params["gameName"];
  //   },
  //   imgSrc() {
  //     return (
  //       "/asset/games/" +
  //       this.gameName +
  //       "/img/" +
  //       this.sectionContent.img +
  //       ".png"
  //     );
  //   },
  //   sectionContentTexts() {
  //     const content = this.sectionContent;
  //     if (content !== undefined) {
  //       return content.text.split("\n");
  //     }
  //     return [];
  //   }
  // }
};
</script>

<style></style>
