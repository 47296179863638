import axios from "axios";
import router from "../router";

export default {
  namespaced: true,
  state() {
    return {
      loading: true,
      config: {
        title: "Placeholder",
        rtp: 0,
        sections: []
      },
      badRequest: false
    };
  },
  getters: {
    rtp(state) {
      return state.config.rtp;
    },
    config(state) {
      return state.config;
    },
    isLoading(state) {
      return state.loading;
    },
    componentData: state => (sectionIndex, contentIndex, componentIndex) => {
      let result =
        state.config.sections[sectionIndex].content[contentIndex].components[
          componentIndex
        ].data;
      if (!result) {
        result = {};
      }
      return result;
    }
  },
  mutations: {
    configLoaded(state, config) {
      state.loading = false;
      state.config = config;
      state.badRequest = false;
    },
    configLoadFailed(state) {
      state.loading = false;
      state.config = null;
      state.badRequest = true;
    }
  },
  actions: {
    async loadJson(context, { url, defaultUrl }) {
      try {
        const config = await context.dispatch("_fetch", url);
        context.commit("configLoaded", config);
        return;
      } catch (e) {
        try {
          const config = await context.dispatch("_fetch", defaultUrl);
          context.commit("configLoaded", config);
          return;
        } catch (e2) {
          context.commit("configLoadFailed");
          router.push("/404");
          return;
        }
      }
    },
    async _fetch(_context, url) {
      try {
        let result = await axios.get(url, {
          responseType: "json",
          headers: {
            "content-type": "application/json",
            Accept: "application/json"
          },
          withCredentials: true
        });
        if (result["data"]) {
          return result.data;
        } else {
          return Promise.reject();
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
};
