<template>
  <component
    v-if="componentCondition"
    :is="component.type"
    :sectionIndex="sectionIndex"
    :contentIndex="contentIndex"
    :componentIndex="componentIndex"
    :storePrefix="storePrefix"
  ></component>
</template>

<script>
import { mapGetters } from "vuex";
import WinLines from "./winLines.vue";
import BetTable from "./betTable.vue";
import SymbolTable from "./symbolTable.vue";
import genericTable from "./genricTable.vue";
import rtp from "./rtp.vue";
import rulesGeneration from "./created.vue";
import jokerBonus from "./jokerBonus.vue";
export default {
  name: "componentWrapper",
  components: {
    WinLines,
    BetTable,
    SymbolTable,
    genericTable,
    rtp,
    rulesGeneration,
    jokerBonus
  },
  props: [
    "sectionIndex",
    "contentIndex",
    "componentIndex",
    "component",
    "storePrefix"
  ],
  computed: {
    ...mapGetters({
      showJokerBonus: "gameData/jokerBonus",
      showBetTable: "gameData/betTable"
    }),
    componentCondition() {
      if (this.component.type === "jokerBonus" && !this.showJokerBonus)
        return false;
      if (this.component.type === "betTable" && !this.showBetTable)
        return false;
      return true;
    }
  }
};
</script>

<style></style>
