<template>
  <b-button
    v-if="fromgame"
    @click="close"
    variant="danger"
    style=""
    class="mr-2 float-right"
    >&#x2715;</b-button
  >
</template>

<script>
export default {
  name: "closeButton",
  props: ["fromgame"],
  data() {
    return {
      // fromgame: false
    };
  },

  computed: {
    /*fromgame() {
        return window.location.search.substring(1).indexOf('fromgame=true') > -1
      }*/
  },

  methods: {
    close() {
      window.close();
    }
  },

  created() {}
};
</script>

<style scoped></style>
