<template>
  <div>
    <div class="head">
      <div></div>
      <div class="title">
        <div>
          <h1 id="header">{{ jsonFile.title }}</h1>
        </div>
      </div>
      <div><close-button :fromgame="fromGame"></close-button></div>
    </div>
    <game storePrefix="json" />
    <div class="logo">
      <img src="../assets/commons/img/logo_kajot.svg" class="img-fluid" />
    </div>
    <div v-if="fetching" style="text-align: center">
      <b-spinner class="spinner light" variant=""></b-spinner>
    </div>
  </div>
</template>

<script>
import CloseButton from "../components/commons/closeButton.vue";
import { mapGetters } from "vuex";
import { PageBase } from "../mixins/pageBase";

export default {
  name: "paragraphPage",
  mixins: [PageBase],
  components: { CloseButton },

  computed: {
    ...mapGetters({
      fromGame: "gameData/fromGame"
    }),
    a() {}
  }
};
</script>

<style scoped>
.spinner {
  position: absolute;
  left: 50%;
  top: 35%;
  width: 4rem;
  height: 4rem;
  color: #f9dc34;
}
</style>
