<template>
  <div>
    <h3 v-if="sectionContent.subHeader">{{ sectionContent.subHeader }}</h3>
    <div v-if="sectionContent.text">
      <p
        v-for="text of sectionContentTexts"
        :key="`section${sectionIndex} content${contentIndex} text${text}`"
        v-html="text"
      ></p>
    </div>
    <component-wrapper
      v-for="(component, componentIndex) of sectionContent.components"
      :key="`section${sectionIndex}-${contentIndex} component${componentIndex}`"
      :component="component"
      :componentIndex="componentIndex"
      :contentIndex="contentIndex"
      :sectionIndex="sectionIndex"
      :storePrefix="storePrefix"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
import componentWrapper from "../componentWrapper.vue";
export default {
  components: { componentWrapper },
  // props: ["sectionIndex", "contentIndex"],
  props: {
    storePrefix: {
      type: String,
      default: "json"
    },
    sectionIndex: {
      type: Number,
      required: true
    },
    contentIndex: {
      type: Number,
      required: true
    }
  },

  beforeCreate() {
    let namespace = this.$options.propsData.storePrefix;
    const { mapGetters } = createNamespacedHelpers(namespace);

    this.$options.computed = {
      ...mapGetters({
        jsonFile: "config"
      }),

      sectionContent() {
        return this.jsonFile.sections[this.sectionIndex].content[
          this.contentIndex
        ];
      },
      sectionContentTexts() {
        const content = this.sectionContent;
        if (content !== undefined) {
          return content.text.split("\n");
        }
        return [];
      }
    };
  }
  // computed: {
  //   // ...mapGetters({
  //   //   jsonFile: "json/config"
  //   // }),
  // }
};
</script>

<style></style>
