<template>
    <div>
      <h1>{{ text }}</h1>
    </div>
</template>

<script>
    export default {
        name: "pageNotFound",
      data() {
          return {
            text: 'Page not found'
          }
      }
    }
</script>

<style scoped>
  div {
    background-color: rgba(61, 61, 61, 0.95);
    width: 100vw;
    height: 100vh;
    text-align: center;
  }

  h1 {
    text-transform: uppercase;
  }
</style>
