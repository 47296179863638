<template>
  <div class="flex-container flex-row flex-wrap justify-content-center">
    <span v-for="(winLine, i) of winLines" :key="i">
      <h5 class="header">{{ i + 1 }}</h5>
      <table class="winline-table">
        <tr v-for="(reel, j) of reels.symbolCount" :key="`${i}-${j}`">
          <td
            v-for="(def, k) of reels.count"
            :key="`${i}-${j}-${k}`"
            class="winline-cell"
            :class="{ active: winLine[k].offs === j }"
          ></td>
        </tr>
      </table>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {},
  computed: {
    ...mapGetters({ winLines: "gameData/winLines", reels: "gameData/reels" })
  }
};
</script>

<style scoped>
.winline-table {
  margin: 0.3em;
}
.winline-cell {
  border: 1px solid var(--winline-table-border-col);
  height: 1em;
  width: 1em;
}

.winline-cell.active {
  background-color: var(--winline-table-winline-col);
}
</style>
