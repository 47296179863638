import { fillWinLinesTable } from "../helpers/window/windowHelpers";

export default {
  namespaced: true,
  state() {
    return {
      currency: "",
      fromgame: false,
      bets: [],
      coins: [],
      min_bet: 0,
      max_bet: 0,
      decimal_places: 2,
      winLines: [],
      reels: {
        count: 0,
        symbolCount: 0
      },
      legislation: "",
      symbol_definitions: [],
      joker_bonus: false,
      showBetTable: true,
      rtp: null
    };
  },
  mutations: {
    init(state, newState) {
      for (let [key, value] of Object.entries(newState)) {
        state[key] = value;
      }
      if (state["winLines"] === undefined) {
        state["winLines"] = fillWinLinesTable(
          state["reels"]["count"],
          state["reels"]["symbolCount"]
        );
      }
    }
  },
  actions: {
    init(context, data) {
      context.commit("init", data);
    }
  },
  getters: {
    betSet(state) {
      return {
        bets: state.bets,
        coins: state.coins
      };
    },
    winLines(state) {
      return state.winLines;
    },
    reels(state) {
      return state.reels;
    },
    fromGame(state) {
      return state.fromgame;
    },
    jokerBonus(state) {
      return state.joker_bonus;
    },
    betTable(state) {
      return state.showBetTable;
    },
    symbolDefinitions(state) {
      return state.symbol_definitions;
    },
    legislation(state) {
      return state.legislation;
    },
    rtp(state) {
      return state.rtp;
    }
  }
};
