<template>
  <div>
    <game storePrefix="jokerBonus" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "jBonus",
  components: {
    Game: () => import("./game/game.vue")
  },

  computed: {
    fileName() {
      return `joker_bonus_${this.$route.params.language}`;
    },

    defaultFileName() {
      return `joker_bonus_en`;
    }
  },
  methods: {
    ...mapActions({
      loadJsonFile: "jokerBonus/loadJson"
    }),
    getUrl(fileName) {
      return `/asset/components/joker_bonus/json/${fileName}.json`;
    }
  },

  created() {
    this.loadJsonFile({
      url: this.getUrl(this.fileName),
      defaultUrl: this.getUrl(this.defaultFileName)
    });
  }
};
</script>

<style></style>
